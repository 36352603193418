<template>
  <AdminView>
    <h1 class="title-1">Exercices</h1>

    <div class="buttons-container">
      <div class="search">
        <Input title="Rechercher" v-model="search" />
      </div>

      <router-link :to="{ name: 'AddExercise' }">
        <Button title="Ajouter" type="secondary">
          <SVGPlus />
        </Button>
      </router-link>
    </div>

    <table>
      <thead>
        <tr>
          <th></th>
          <th>Image</th>
          <th class="sortable" @click="sort('code')">Code</th>
          <th class="sortable" @click="sort('name')">Titre</th>
          <th class="sortable" @click="sort('advices')">Conseils</th>
          <th>Mvt RC</th>
          <th class="sortable" @click="sort('needReeducator')">Rééduc</th>
          <th class="sortable" @click="sort('type')">Thème</th>
          <th class="sortable" @click="sort('intensity')">Intensité</th>
          <th class="sortable" @click="sort('bodyPart')">Parties du corps</th>
          <th class="sortable" @click="sort('position')">Position</th>
          <th>Matériel</th>
          <th class="sortable" @click="sort('defaultDuration')">Durée</th>
          <th class="sortable" @click="sort('defaultCount')">Nb séries</th>
          <th class="sortable" @click="sort('defaultRestTime')">Tps récup</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(exercise, i) in sortedExercises" :key="i">
          <td class="edit">
            <router-link
              :to="{ name: 'Exercise', params: { id: exercise.id } }"
              title="Modifier"
            >
              <SVGEdit />
            </router-link>
          </td>
          <td class="image">
            <img :src="exercise.thumbnailUrl" v-if="exercise.thumbnailUrl" />
          </td>
          <td>{{ exercise.code }}</td>
          <td>{{ exercise.name }}</td>
          <td>{{ advicesCount(exercise) }}</td>
          <td class="icon"><SVGCheck v-if="exercise.hasRC" /></td>
          <td class="icon"><SVGCheck v-if="exercise.needReeducator" /></td>
          <td>{{ $t(`exerciseType.${exercise.type}`) }}</td>
          <td>
            {{ !exercise.intensity ? "" : $t(`difficultyLevels.${exercise.intensity}`) }}
          </td>
          <td>{{ $t(`bodyParts.${exercise.bodyPart}`) }}</td>
          <td>{{ $t(`position.${exercise.position}`) }}</td>
          <td>{{ exercise.equipments.reduce((acc, eq) => acc + "" + eq.name, "") }}</td>
          <td>{{ exercise.defaultDuration }}s</td>
          <td>{{ exercise.defaultCount }}</td>
          <td>{{ exercise.defaultRestTime }}s</td>
        </tr>
      </tbody>
    </table>
  </AdminView>
</template>

<script>
import { mapGetters } from "vuex"
import AdminView from "@/components/AdminView"
import Input from "@/components/Forms/Fields/Input"
import Button from "@/components/Button"
import SVGCheck from "@/assets/icons/check.svg"
import SVGEdit from "@/assets/icons/edit.svg"
import SVGPlus from "@/assets/icons/plus.svg"

export default {
  name: "ExercisesList",
  components: {
    AdminView,
    Input,
    Button,
    SVGCheck,
    SVGEdit,
    SVGPlus
  },
  data() {
    return {
      search: "",
      sortBy: "name"
    }
  },
  async mounted() {
    await this.$store.dispatch("admin/getExercises")
  },
  methods: {
    sort(type) {
      if (this.sortBy === type) {
        this.sortBy = "-" + type
      } else {
        this.sortBy = type
      }
    },
    advicesCount(exercise) {
      let count = 0
      count += exercise.advice1Url ? 1 : 0
      count += exercise.advice2Url ? 1 : 0
      count += exercise.advice1Url ? 1 : 0
      return count
    }
  },
  computed: {
    ...mapGetters({
      exercises: "admin/exercises"
    }),
    filteredExercises() {
      return this.exercises.filter((p) =>
        p.name.toLowerCase().includes(this.search.toLowerCase())
      )
    },
    sortedExercises() {
      const order = this.sortBy.startsWith("-") ? -1 : 1
      const sortBy = order < 0 ? this.sortBy.substring(1) : this.sortBy

      return [...this.filteredExercises].sort((a, b) => {
        if (sortBy == "advices") {
          return this.advicesCount(a) > this.advicesCount(b) ? 1 * order : -1 * order
        } else {
          return a[sortBy] > b[sortBy] ? 1 * order : -1 * order
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 40px 0;

  div {
    flex: 0;
  }
}

.exercises-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

table {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;

  thead {
    font-size: 1.1rem;
    font-weight: 420;
    text-align: left;
    background: $main-color;
    color: $white-color;

    .sortable {
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(2n) {
        background: #f7f7f7;
      }
    }
  }

  th,
  td {
    padding: 8px 10px;
    vertical-align: middle;
  }

  th {
    padding: 10px;
    user-select: none;
  }

  .image {
    padding: 2px;
    width: 50px;
    height: 38px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 5px;
    }
  }

  .icon {
    text-align: center;

    svg {
      width: 20px;
      color: $main-color;
    }
  }

  .edit {
    a {
      display: block;

      svg {
        width: 20px;
        transition: all 0.25s;
      }

      &:hover {
        svg {
          fill: $main-color;
        }
      }
    }
  }
}
</style>
